@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400;500;700&family=Open+Sans&family=Poppins&display=swap");

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
.root {
  height: 100%;
  width: 100%;
  font-family: "Roboto", sans-serif;
}

.login-bg {
  background-color: #2775b4;
  width: 100%;
  height: 100%;
  position: relative;
}

.login {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border-radius: 10px;
  box-shadow: 10px 10px 15px rgba(0, 0, 0, 0.05);
}

.login h1 {
  padding: 20px 0 10px;
  border-bottom: 1px silver solid;
  width: 100%;
  text-align: center;
}

.error {
  background-color: rgb(253, 150, 150);
  width: 100%;
  line-height: 40px;
  margin-top: 10px;
  text-align: center;
  font-size: 0.9rem;
  border-radius: 5px;
}

.form {
  padding: 0 30px;
  width: 100%;
}

.form-control {
  position: relative;
  border-bottom: 2px solid #adadad;
  margin: 30px 0;
}

.form-control input {
  width: 100%;
  padding: 0 5px;
  height: 40px;
  font-size: 1rem;
  border: none;
  background: none;
  outline: none;
}

.form-control label {
  position: absolute;
  top: 50%;
  left: 5px;
  color: #adadad;
  transform: translateY(-50%);
  font-size: 0.9rem;
  pointer-events: none;
  transition: 0.5s;
}

.form-control span::before {
  content: "";
  position: absolute;
  top: 40px;
  left: 0;
  width: 0%;
  height: 2px;
  background: #2691d9;
  transition: 0.5s;
}

.form-control input:focus ~ label,
.form-control input:valid ~ label {
  top: -10px;
  color: #2691d9;
  font-size: 0.8rem;
}

.form-control input:focus ~ span::before,
.form-control input:valid ~ span::before {
  width: 100%;
}

.login .btn {
  background-color: #2196f3;
  height: 50px;
  border-radius: 25px;
  font-size: 18px;
  margin-bottom: 30px;
}

.login .btn:hover {
  background-color: #1e81d3;
}

.user-icon {
  position: absolute;
  bottom: 20px;
  left: 10px;
  width: 50px;
  height: 50px;
  background-color: #3ba7ff;
  z-index: 10;
  border-radius: 50%;
  text-align: center;
  color: white;
  padding-top: 8px;
}

.user-info {
  position: absolute;
  bottom: 20px;
  left: 70px;
  background-color: white;
  padding: 10px;
  color: grey;
  border-radius: 10px;
}

.user-info::before {
  position: absolute;
  bottom: 20px;
  left: -4px;
  content: "";
  width: 10px;
  height: 10px;
  background-color: white;
  transform: rotate(45deg);
}

.user-info .btn {
  background-color: #2196f3;
}

.modal-bg {
  position: absolute;
  z-index: 3;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.2);
}

.modal {
  margin: 200px auto 0;
  width: 250px;
  background-color: white;
  border-radius: 5px;
  text-align: center;
  padding: 30px 20px 20px;
}

.modal-btn {
  margin-top: 10px;
}

.modal-btn .btn {
  width: 45%;
  margin: 5px;
}

.search-container {
  position: absolute;
  top: 1rem;
  left: 50%;
  transform: translateX(-50%);
  width: 90%;
  max-width: 400px;
  height: auto;
  z-index: 2;
}

.search {
  display: flex;
  align-items: center;
  border-radius: 1.5rem;
  border: 0.13rem solid black;
  background-color: white;
  padding-left: 20px;
}

.search input {
  padding: 0.5rem;
  font-size: 1.2rem;
  width: stretch;
  border: none;
}

.search input:focus {
  outline: none;
}

.search-cancel {
  margin: 0.2rem 0.6rem 0 0.5rem;
  padding: 0;
}

.search-cancel:hover {
  cursor: pointer;
}

.search img {
  height: 1rem;
}

.searched-info-window,
.selected-info-window {
  padding-bottom: 12px;
  padding-right: 12px;
}

.searched-info-window h1,
.selected-info-window h1 {
  font-size: 1.3rem;
  margin-bottom: 0.3rem;
}

.searched-info-window .btn {
  background-color: #2196f3;
}

.searched-info-window .btn:hover {
  background-color: #1e81d3;
}

.played-by {
  margin-top: 0.5rem;
}

.marker-label {
  background-color: grey;
  width: 15px;
  height: 15px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  display: flex;
  padding-left: 1px;
}

.played {
  margin-top: 0.5rem;
  display: flex;
  align-items: center;
}

.played p {
  font-size: 0.9rem;
  font-weight: 500;
  margin-right: 5px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

.switch .toggle {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: red;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 24px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 20px;
  width: 20px;
  left: 3px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
  border-radius: 50%;
}

.toggle.checked + .slider {
  background-color: green;
}

.toggle.checked + .slider:before {
  -webkit-transform: translateX(15px);
  -ms-transform: translateX(15px);
  transform: translateX(15px);
}

.btn {
  margin-top: 0.5rem;
  width: 100%;
  height: 2rem;
  border: none;
  border-radius: 1rem;
  color: white;
  font-weight: 600;
  cursor: pointer;
}

.cancel {
  background-color: #ccc;
}

.cancel:hover {
  background-color: rgb(161, 161, 161);
}

.delete {
  background-color: red;
}

.delete:hover {
  background-color: rgb(235, 0, 0);
}

.gm-style-iw-d {
  overflow: hidden !important;
}

.loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
